import React from "react";
import PropTypes from "prop-types";
import Page from "../components/Page";
import staticData from "../data/index";
import processData from "../data/processData";
import { graphql } from "gatsby";

const JobTemplate = ({ pageContext, data }) => {
  return (
    <Page
      name={pageContext.name}
      data={processData(staticData[pageContext.name], data, pageContext.name)}
    />
  );
};

JobTemplate.propTypes = {
  pageContext: PropTypes.shape(),
  data: PropTypes.shape(),
};

export const query = graphql`
  query($id: ID!) {
    cms {
      job(id: $id) {
        id
        slug
        job_id
        title
        content
        active
        created_at
        updated_at
        department {
          id
          name
          slug
          jobs {
            id
            title
            active
            slug
          }
        }
      }
    }
  }
`;

export default JobTemplate;
